import ExportedImage from 'next-image-export-optimizer';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

import KomoDeFi_DeFi from '@/public/images/lp/home/komodefi/KomoDeFi_DeFi.png';
import KomoDeFiDEX from '@/public/images/lp/home/komodefi/KomoDeFi_Exchange.png';
import KomoDeFiWallet from '@/public/images/lp/home/komodefi/KomoDeFi_Wallet.png';

const KomoDeFi = () => {
  return (
    <section className='my-20 text-white lg:my-24 2xl:my-36'>
      <AnimateWhileInView delay={0.2}>
        <h2 className='mx-auto max-w-[756px] text-center font-heading text-3xl font-semibold text-white sm:text-4xl lg:mb-[46px] lg:text-5xl -lg:text-center'>
          Komodo DeFi Framework
        </h2>
      </AnimateWhileInView>
      <div className='flex w-full flex-col items-center justify-between lg:mb-[100px] lg:w-full lg:flex-row xlm:w-[1180px]'>
        <AnimateWhileInView>
          <ExportedImage
            placeholder='empty'
            className='h-auto w-[350px] xl:w-[420px] xlm:w-[516px]'
            src={KomoDeFiWallet}
            alt='Build a non-custodial wallet'
          />
        </AnimateWhileInView>
        <div className='flex w-2/4 flex-col space-y-4 lg:w-[570px] xlm:w-[625px] -md:mb-12 -md:w-[80%] -md:items-center -sm:mx-auto -sm:mt-4 -sm:w-[90%]'>
          <AnimateWhileInView delay={0.25}>
            <h3 className='font-heading text-4xl font-semibold leading-[46px] xlm:text-5xl xlm:leading-[54px] -lg:text-4xl -sm:text-2xl'>
              <span className='main-gradient'>Build</span> A Non-Custodial Wallet
            </h3>
          </AnimateWhileInView>
          <AnimateWhileInView delay={0.3}>
            <ul className='list-inside list-disc text-lg leading-9 xlm:text-xl xlm:leading-10 -lg:text-lg -sm:text-sm'>
              <li>A secure place to store thousands of cryptocurrencies</li>
              <li>Have control of your private keys, own your own coins</li>
              <li>Supported on Web, Mobile, Desktop, Browser Extension and CLI</li>
            </ul>
          </AnimateWhileInView>
        </div>
      </div>
      <div className='mx-auto flex flex-col items-center justify-between lg:my-[100px] lg:w-[990px] lg:flex-row xlm:w-[1180px]'>
        <div className='flex w-2/4 flex-col space-y-4 lg:w-[625px] -lg:order-last -md:mb-12 -md:w-[80%] -md:items-center -sm:mx-auto -sm:mt-4 -sm:w-[90%]'>
          <AnimateWhileInView delay={0.25}>
            <h3 className='font-heading text-4xl font-semibold leading-[46px] xlm:text-5xl xlm:leading-[54px] -lg:text-4xl -sm:text-2xl'>
              <span className='main-gradient'>Launch</span> A Cross-Protocol Decentralized Exchange
            </h3>
          </AnimateWhileInView>
          <AnimateWhileInView delay={0.3}>
            <ul className='list-inside list-disc text-lg leading-9 xlm:text-xl xlm:leading-10 -lg:text-lg -sm:text-sm'>
              <li>The widest cross-protocol support in the blockchain sector</li>
              <li>A peer-to-peer network powered by atomic swap technology</li>
              <li>No restrictions on trading pairs, trade any coin for another coin</li>
            </ul>
          </AnimateWhileInView>
        </div>
        <AnimateWhileInView>
          <ExportedImage
            placeholder='empty'
            className='h-auto w-[350px] xl:w-[450px] xlm:w-[563px]'
            src={KomoDeFiDEX}
            alt='Launch a cross-protocol decentralized exchange'
          />
        </AnimateWhileInView>
      </div>
      <div className='mx-auto my-5 flex flex-col items-center justify-between sm:my-10 lg:my-[100px] lg:w-[990px] lg:flex-row xlm:w-[1180px]'>
        <AnimateWhileInView>
          <ExportedImage
            placeholder='empty'
            className='h-auto w-[350px] xl:w-[450px] xlm:w-[554px]'
            src={KomoDeFi_DeFi}
            alt='Develop an enterprise-ready DeFi platform'
          />
        </AnimateWhileInView>
        <div className='flex w-2/4 flex-col space-y-4 lg:w-[625px] lg:pl-16 -md:mb-12 -md:w-[80%] -md:items-center -sm:mx-auto -sm:mt-4 -sm:w-[90%]'>
          <AnimateWhileInView delay={0.25}>
            <h3 className='font-heading text-4xl font-semibold leading-[46px] xlm:text-5xl xlm:leading-[54px] -lg:text-4xl -sm:text-2xl'>
              <span className='main-gradient'>Develop</span> An Enterprise-Ready DeFi Platform
            </h3>
          </AnimateWhileInView>
          <AnimateWhileInView delay={0.3}>
            <ul className='list-inside list-disc text-lg leading-9 xlm:text-xl xlm:leading-10 -lg:text-lg -sm:text-sm'>
              <li>A custom IDO Portal or marketplace solution</li>
              <li>Bridge crypto assets</li>
              <li>Combine with other Komodo products for additional functionality</li>
            </ul>
          </AnimateWhileInView>
        </div>
      </div>
    </section>
  );
};

export default KomoDeFi;
