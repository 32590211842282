import { useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';

import { AnimatePresence, motion } from 'framer-motion';

import carouselLeft from '@/public/images/lp/home/carousel-left.png';
import carouselRight from '@/public/images/lp/home/carousel-right.png';

//https://www.freecodecamp.org/news/build-an-image-carousel-with-react-and-framer-motion/
//https://github.com/Cejay101/ImageCarousel
const Carousel = ({ categories }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const slideVariants = {
    hiddenRight: {
      x: '100%',
      opacity: 0,
    },
    hiddenLeft: {
      x: '-100%',
      opacity: 0,
    },
    visible: {
      x: '0',
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.5,
      },
    },
  };
  const slidersVariants = {
    hover: {
      scale: 1.2,
      backgroundColor: '#bfff008e',
    },
  };
  const dotsVariants = {
    initial: {
      y: 0,
    },
    animate: {
      y: -10,
      scale: 1.2,
      transition: { type: 'spring', stiffness: 1000, damping: '10' },
    },
    hover: {
      scale: 1.1,
      transition: { duration: 0.2 },
    },
  };

  const handleNext = () => {
    setDirection('right');
    setCurrentIndex((prevIndex) => (prevIndex + 1 === categories.length ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    setDirection('left');

    setCurrentIndex((prevIndex) => (prevIndex - 1 < 0 ? categories.length - 1 : prevIndex - 1));
  };

  const handleDotClick = (index) => {
    setDirection(index > currentIndex ? 'right' : 'left');
    setCurrentIndex(index);
  };

  return (
    <div className='carousel'>
      <div className='carousel-categories relative'>
        <AnimatePresence>
          {/* <motion.div
                        key={currentIndex}

                        initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
                        animate="visible"
                        exit="exit"
                        variants={slideVariants}
                    >
                        <ExportedImage placeholder='empty' src={categories[currentIndex].image} key={currentIndex} />
                    </motion.div> */}
          <div className='mx-auto flex flex-row items-center justify-between xl:w-[1180px] -lg:flex-col'>
            <div className='relative flex h-[292.35px] w-[380px] items-center xl:w-[483px] -sm:h-[200px] -sm:w-[300px]'>
              <div className='absolute left-[29.77px] top-[215.33px] z-10 h-[67.44px] w-[300px] rounded-full bg-gradient-to-l from-[#00a3ff] to-[#1d9ce4] blur-[44.50px] xl:w-[416.82px] -sm:top-[129px] -sm:w-[205px] -sm:blur-[24.50px]' />
              <div className='absolute left-0 top-0 z-10 h-[292.35px] w-[380px] rounded-[14px] border border-black bg-[#0d1d2a]/80 backdrop-blur-[81.20px] xl:w-[483px] -sm:h-[200px] -sm:w-[300px]' />
              <ExportedImage
                placeholder='empty'
                alt={categories[currentIndex].name}
                className='relative z-20 m-auto h-auto w-[70%] xl:w-[90%] -sm:w-[80%]'
                src={categories[currentIndex].image}
                key={currentIndex}
              />
            </div>
            <div className='relative flex w-[500px] flex-col text-left xl:w-[565px] -lg:mt-10 -sm:w-[300px]'>
              <h3 className='font-display font-semibold leading-[54px] text-white lg:text-5xl -lg:text-4xl -sm:text-3xl'>
                {categories[currentIndex].name}
              </h3>
              <li className='mt-6 text-[18px] font-normal text-white xl:text-[19px] -sm:text-sm -sm:leading-[20px]'>
                {categories[currentIndex].description}
              </li>
            </div>
          </div>
        </AnimatePresence>
      </div>

      <div className='mt-4 flex flex-row items-center justify-center gap-x-4 -sm:mt-6'>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <ExportedImage
            placeholder='empty'
            src={carouselLeft}
            className='h-auto w-[78px] cursor-pointer -sm:w-[50px]'
            onClick={handlePrevious}
            alt='previous item'
          />
        </motion.div>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <ExportedImage
            placeholder='empty'
            src={carouselRight}
            className='h-auto w-[78px] cursor-pointer -sm:w-[50px]'
            onClick={handleNext}
            alt='next item'
          />
        </motion.div>
      </div>
    </div>
  );
};
export default Carousel;
