import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

import { siteData } from '@/data/site';
import discord from '@/public/images/lp/index/discord.png';
import reddit from '@/public/images/lp/index/reddit.png';
import telegram from '@/public/images/lp/index/telegram.png';
import twitter from '@/public/images/lp/index/twitter.png';

const socialIcons = [
  {
    name: 'Discord',
    icon: discord,
    link: siteData.social.discord,
    lgWidth: 90.21,
    smWidth: 50.21,
  },
  {
    name: 'X.com',
    icon: twitter,
    link: siteData.social.twitter,
    lgWidth: 125.21,
    smWidth: 70.21,
  },
  {
    name: 'Reddit',
    icon: reddit,
    link: siteData.social.reddit,
    lgWidth: 125.21,
    smWidth: 70.21,
  },
  {
    name: 'Telegram',
    icon: telegram,
    link: siteData.social.telegram,
    lgWidth: 125.21,
    smWidth: 70.21,
  },
];

export const JoinCommunity = () => {
  return (
    <>
      <section className='my-20 text-center text-white lg:my-24 2xl:my-36'>
        <div className='dummy hidden w-[50.21px] w-[70.21px] lg:w-[125.21px] lg:w-[90.21px]'></div>
        <h2 className='mx-auto mb-[10px] max-w-[756px] font-heading text-3xl font-semibold text-white sm:text-4xl lg:text-5xl'>
          Join our community
        </h2>
        <p className='text-base font-normal leading-[18px] -sm:mb-6'>
          Hang out with the community, get acquainted or just share
          <br className='hidden md:block' /> knowledge from the crypto world
        </p>
        <div className='mx-auto flex w-[341px] flex-row items-center justify-center pt-4 2xs:w-full md:w-[633px] md:pt-8 lg:w-auto lg:gap-x-[106.49px] lg:pt-[46px] -sm:flex-wrap -sm:justify-between -sm:gap-y-8'>
          {socialIcons.map((social, i) => {
            const { name, icon, link, lgWidth, smWidth } = social;
            return (
              <AnimateWhileInView delay={(i + 2) * 0.1} key={social.name}>
                <Link
                  href={link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='all-index-join_community-discord flex flex-col items-center'
                >
                  <ExportedImage
                    placeholder='empty'
                    src={icon}
                    alt={name}
                    className={`h-auto lg:w-[${lgWidth}px] w-[${smWidth}px]`}
                  />
                  <p className='text-center text-[18px] font-normal leading-[34.85px] lg:text-[30.98px]'>
                    {name}
                  </p>
                </Link>
              </AnimateWhileInView>
            );
          })}
        </div>
      </section>
    </>
  );
};
