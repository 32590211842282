import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import ExportedImage from 'next-image-export-optimizer';

import fs from 'fs';

import Airdrop from '@/components/shared/lp/home/Airdrop';
import AsFeaturedOn from '@/components/shared/lp/home/AsFeaturedOn';
import CoinLogoScroller from '@/components/shared/lp/home/CoinLogoScroller';
import CoinLogoScrollerCopy from '@/components/shared/lp/home/CoinLogoScrollerCopy';
import DecentralizationSpectrum from '@/components/shared/lp/home/DecentralizationSpectrum';
import Features from '@/components/shared/lp/home/Features';
import Hero from '@/components/shared/lp/home/Hero';
import KomoDeFi from '@/components/shared/lp/home/KomoDeFi';
import LatestNews from '@/components/shared/lp/home/LatestNews';
import PortfolioManagement from '@/components/shared/lp/home/PortfolioManagement';
import Secure from '@/components/shared/lp/home/Secure';
import Showcase from '@/components/shared/lp/home/Showcase';
import SpendYourCrypto from '@/components/shared/lp/home/SpendYourCrypto';
import Stats from '@/components/shared/lp/home/Stats';
import Trustless from '@/components/shared/lp/home/Trustless';
import WhyKomodo from '@/components/shared/lp/home/WhyKomodo';
import { Crypto500 } from '@/components/shared/lp/komodo-wallet/Crypto500';
import { JoinCommunity } from '@/components/shared/lp/komodo-wallet/JoinCommunity';
import { SecureTrading } from '@/components/shared/lp/komodo-wallet/SecureTrading';
import { WalletBridge } from '@/components/shared/lp/komodo-wallet/WalletBridge';

import { hack, inter, montserrat } from '@/styles/fonts';

import { apiEndpoints } from '@/data/API_ENDPOINTS';
import blogData from '@/data/blog/en/allPosts.json';
import per13 from '@/public/images/lp/index/13-per.png';
import komodianLogo from '@/public/images/lp/index/Komodian-logo-on-hoody-cut.png';
import atomHero from '@/public/images/lp/index/atom-hero.png';
import iosLaptop from '@/public/images/lp/index/ios-laptop.png';
import kmdEth from '@/public/images/lp/index/kmd-eth.png';
import now from '@/public/images/lp/index/now.png';
import p2p from '@/public/images/lp/index/p2p.png';
import { fetchHandler } from '@/shared-functions/fetchHandler';
import { calculateTradingPairs } from '@/shared-functions/helpers.js';
import { CoinsType, TradingData } from '@/types';

export const getStaticProps = (async () => {
  let tradingData: TradingData;
  try {
    tradingData = await fetchHandler(apiEndpoints.stats);
  } catch (error) {
    console.error('Error fetching trading data:', error);
    tradingData = {
      swaps_24hr: 92,
      swaps_7d: 942,
      swaps_14d: 2286,
      swaps_30d: 4971,
      swaps_all_time: 446212,
      current_liquidity: 0,
      volume_24hr: 0,
    };
  }

  const assetsListed: CoinsType[] = await fetchHandler(apiEndpoints.coins);

  const tradeableAssetsList = assetsListed.filter((asset) => asset.wallet_only !== true);

  const tradingPairs = calculateTradingPairs(tradeableAssetsList.length);

  let blogPosts = blogData.slice(0, 3).map((post) => {
    let postData = fs.readFileSync(`./src/data/blog/en/posts/${post.slug}.json`, 'utf-8');
    let {
      feature_image_info,
      permalink,
      iso_published_at,
      title,
      meta_description,
      readable_published_at,
      primary_tag,
      readingTime,
    } = JSON.parse(postData);
    return {
      feature_image_info,
      permalink,
      iso_published_at,
      title,
      meta_description,
      readable_published_at,
      tagName: primary_tag.name,
      readingTime,
    };
  });

  return {
    props: {
      title: 'Komodo Platform | All-in-One Blockchain & Crypto Solution',
      description:
        'Open-source blockchain technology provider and the home of Komodo Wallet — a non-custodial crypto wallet, cross-chain DEX, and bridge rolled into one app',
      tradingData,
      assetsListed: assetsListed.length,
      tradingPairs,
      blogPosts,
    },
  };
}) satisfies GetStaticProps<{
  title: string;
  description: string;
  tradingData: TradingData;
  assetsListed: number;
  tradingPairs: number;
  blogPosts: any;
}>;

const Home = ({
  tradingData,
  assetsListed,
  tradingPairs,
  blogPosts,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <>
      <div className='absolute z-[11] h-full w-full overflow-hidden opacity-70'>
        {/* <div className='index-ellipse-1 absolute z-[-10]'></div>
        <div className='index-ellipse-2 absolute z-[-10]'></div>
        <div className='index-ellipse-3 absolute z-[-10]'></div>
        <div className='index-ellipse-4 absolute z-[-10]'></div> */}
      </div>
      <main className='container relative z-[100] mx-auto font-sans text-white md:grid md:grid-cols-24 md:gap-0'>
        <div className='lp-ellipse-223 absolute left-[30%] top-[-200px] z-[-10] h-[359px] w-[287px] -translate-x-1/2 lg:left-[40%] -sm:top-[-200px]'></div>
        <div className='lp-ellipse-224 absolute left-[-350px] top-[163px] z-[-10] h-[515px] w-[492px] xl:left-[-300px] -sm:left-[-400px] -sm:top-[400px]'></div>
        <div className='lp-ellipse-232 absolute right-[-500px] top-[800px] z-[-10] h-[733px] w-[700px] -sm:right-[-300px]'></div>
        <div className='lp-ellipse-233 absolute left-[-600px] top-[1700px] z-[-10] h-[783px] w-[749px]'></div>
        <div className='lp-ellipse-234 absolute right-[-700px] top-[2700px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-236 absolute right-[-600px] top-[4500px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-237 absolute left-[-600px] top-[4900px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-238 absolute right-[-600px] top-[5600px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-239 absolute left-[-600px] top-[6600px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-239 absolute right-[-600px] top-[7800px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-240 absolute left-[-600px] top-[8200px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-241 absolute right-[-600px] top-[9000px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-239 absolute bottom-[-500px] left-[-300px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='hidden xl:col-span-2 xl:block'></div>
        <div className='relative w-full max-w-7xl px-4 xs:px-8 md:col-span-24 md:mx-auto xl:col-span-20 xl:px-0'>
          <Hero />

          <Stats
            tradingData={tradingData}
            assetsListed={assetsListed}
            tradingPairs={tradingPairs}
          />
          <AsFeaturedOn />

          {/* <Airdrop /> */}
          {/* <CoinLogoScroller /> */}
          {/* <CoinLogoScrollerCopy /> */}
          <Secure />
          <Trustless />
          <PortfolioManagement />

          {/* <SpendYourCrypto /> */}
          <WhyKomodo />
          <Features />
          <KomoDeFi />
          <Showcase />

          <JoinCommunity />
          <LatestNews blogPosts={blogPosts} />
        </div>
        <div className='hidden xl:col-span-2 xl:block'></div>
      </main>
    </>
  );
};

export default Home;
