import React, { useEffect, useRef, useState } from 'react';

import { animate, motion } from 'framer-motion';
import millify from 'millify';

interface CounterProps {
  from: number;
  to: number;
  format: any;
  setIsCountingUp: (isCountingUp: boolean) => void;
}

const formatNumber = (num: any, format: any) => {
  if (format == 'k') {
    //return (num / 1000).toFixed(1) + 'K';
    return millify(num);
  }
  if (format == '%') {
    return num + '%';
  }
  return num.toLocaleString();
};

const NumberCounter: React.FC<CounterProps> = ({ from, to, format, setIsCountingUp }) => {
  const nodeRef = useRef<HTMLParagraphElement | null>(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const node = nodeRef.current;
    if (!node) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          }
        });
      },
      { threshold: 0.1 },
    );

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, []);

  useEffect(() => {
    if (!isInView) return;

    const node = nodeRef.current;
    if (!node) return;

    setIsCountingUp(true);

    const controls = animate(from, to, {
      duration: 4,
      onUpdate(value) {
        node.textContent = formatNumber(Math.round(value), format);
      },
      onComplete() {
        setIsCountingUp(false);
      },
    });

    return () => {
      controls.stop();
      setIsCountingUp(false);
    };
  }, [from, to, isInView, setIsCountingUp, format]);

  return (
    <motion.p
      ref={nodeRef}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1 } : {}}
      transition={{ duration: 1 }}
    />
  );
};

export default NumberCounter;
