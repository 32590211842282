import { Variants, motion } from 'framer-motion';

export default function AnimateWhileInView({
  children,
  delay,
  variants,
  amountVisible = 'some',
  classNaming,
}: {
  children: JSX.Element;
  delay?: number;
  variants?: Variants;
  amountVisible?: any;
  classNaming?: string;
}) {
  // Bounce in like Moonfi site
  // const animationVariants: Variants = {
  //   offscreen: {
  //     y: 100,
  //     opacity: 0,
  //   },
  //   onscreen: {
  //     y: 0,
  //     opacity: 1,
  //     transition: {
  //       type: "spring",
  //       bounce: 0.4,
  //       duration: 0.8,
  //       delay: delay ? delay : 0,
  //     },
  //   },
  // };
  const animationVariants: Variants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: delay ? delay : 0,
      },
    },
  };
  return (
    <motion.div
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: true, amount: amountVisible }}
      className={classNaming}
    >
      <motion.div variants={variants ? variants : animationVariants}>{children}</motion.div>
    </motion.div>
    // <div> {children}</div>
  );
}
