import ExportedImage from 'next-image-export-optimizer';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';
import CtaButton from '@/components/shared/lp/CtaButton';

import { siteData } from '@/data/site';
import decentralized from '@/public/images/lp/home/decentralized.png';
import protocolSupport from '@/public/images/lp/home/protocolSupport.png';

const ProtocolSupport = () => {
  return (
    <section className='mx-auto my-10 flex items-center justify-between gap-x-[82px] text-white lg:my-24 lg:w-full 2xl:my-36 -lg:flex-col'>
      <div className='flex w-1/2 flex-col space-y-4 md:w-1/2 -lg:mb-12 -lg:w-[80%] -lg:items-center -lg:text-center -sm:w-auto'>
        <AnimateWhileInView delay={0.1}>
          <h2 className='text-base font-normal uppercase leading-[20px] tracking-widest text-[#60A5FA] -lg:text-sm -lg:leading-[17px]'>
            Secure
          </h2>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.15}>
          <h3 className='font-heading text-3xl font-semibold sm:text-4xl lg:text-5xl'>
            Fully Non-Custodial & Decentralized
          </h3>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.2}>
          <p className='text-xl leading-10 -lg:text-lg'>
            Seed Phrases/Private Keys and all other sensitive data generated by the wallet is
            managed on your own device and never leaves it. You are always in full control. The DEX
            is fully decentralized with no middleman. Trade P2P using atomic swaps.
          </p>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.2}>
          <CtaButton href={siteData.webDEXlink} newtab='yes'>
            Get Started
          </CtaButton>
        </AnimateWhileInView>
      </div>
      <AnimateWhileInView
        delay={0.3}
        classNaming='flex w-full items-center justify-center pt-[60px] lg:w-1/2 lg:pt-0'
      >
        <ExportedImage
          placeholder='empty'
          className='h-auto w-[480px] 2xl:w-[510px]'
          src={decentralized}
          alt='Fully non-custodial & decentralized'
        />
      </AnimateWhileInView>
    </section>
  );
};

export default ProtocolSupport;
