import ExportedImage from 'next-image-export-optimizer';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';
import CtaButton from '@/components/shared/lp/CtaButton';

import { siteData } from '@/data/site';
import portfolioMangement from '@/public/images/lp/home/portfolio-management-1.png';
import protocolSupport from '@/public/images/lp/home/protocolSupport.png';

const ProtocolSupport = () => {
  return (
    <section className='mx-auto my-10 flex items-center justify-between gap-x-[82px] text-white lg:my-24 lg:w-full 2xl:my-36 -lg:flex-col'>
      <div className='flex w-1/2 flex-col space-y-4 -lg:mb-12 -lg:w-[80%] -lg:items-center -lg:text-center -sm:w-auto'>
        <AnimateWhileInView delay={0.1}>
          <h2 className='text-base font-normal uppercase leading-[20px] tracking-widest text-[#60A5FA] -lg:text-sm -lg:leading-[17px]'>
            Portfolio management
          </h2>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.15}>
          <h3 className='font-heading text-5xl font-semibold leading-[4rem] -lg:text-4xl'>
            Supports Over 99% of Crypto Assets
          </h3>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.2}>
          <p className='text-xl leading-10 -lg:text-lg'>
            Effortlessly manage your crypto assets with the Komodo Wallet, supporting 99% of all
            blockchains and cryptocurrencies. HODL and trade BTC, KMD, ETH, BNB, MATIC, ATOM and
            many more crypto assets from any device or OS.
          </p>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.2}>
          <CtaButton href={siteData.webDEXlink} newtab='yes'>
            Get Started
          </CtaButton>
        </AnimateWhileInView>
      </div>
      <AnimateWhileInView delay={0.3} classNaming='w-full sm:w-[80%] lg:w-1/2 -lg:mx-auto'>
        <ExportedImage
          placeholder='empty'
          className='h-auto w-full lg:w-[450px] 2xl:w-[484px]'
          src={portfolioMangement}
          alt='Supports Over 99% of Crypto Assets'
        />
      </AnimateWhileInView>
    </section>
  );
};

export default ProtocolSupport;
