import React, { useState } from 'react';

import useSWR from 'swr';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';
import NumberCounter from '@/components/shared/NumberCounter';

import { apiEndpoints } from '@/data/API_ENDPOINTS';
import { fetchHandler } from '@/shared-functions/fetchHandler';
import { TradingData } from '@/types';

//import CountUp from "../../CountUp"; TODO: CountUp is concise but wrong, keeps increasing uptime higher than 100 in dev when js file is saved. also, doesn't seem to honor the viewport some/all prop

export default function Stats({
  tradingData,
  tradingPairs,
  assetsListed,
}: {
  tradingData: TradingData;
  tradingPairs: number;
  assetsListed: number;
}) {
  const { data: trades } = useSWR(apiEndpoints.stats, fetchHandler, {
    refreshInterval: 1000 * 60 * 2, // every 2 minutes
    fallback: {
      [apiEndpoints.stats]: tradingData,
    },
  });
  const stats = [
    {
      id: 1,
      name: 'Trades Completed',
      value: trades?.swaps_all_time,
      defaultValue: 200000,
    },
    {
      id: 2,
      name: 'Supported Assets',
      value: assetsListed,
      defaultValue: 300,
    },
    {
      id: 3,
      name: 'Tradable Pairs',
      value: tradingPairs,
      defaultValue: 100000,
    },
    { id: 4, name: 'Uptime', value: 100, defaultValue: 80 },
  ];
  const [isCountingUp, setIsCountingUp] = useState(false);
  return (
    <div className='relative isolate mx-auto overflow-hidden rounded-lg py-6 lg:py-12'>
      <AnimateWhileInView delay={0.2}>
        <dl className='grid grid-cols-2 gap-x-5 gap-y-5 text-center lg:grid-cols-4 3xl:gap-x-8'>
          {stats.map((stat, index) => (
            <div
              key={stat.id}
              className={`stat-card mx-auto flex h-[155px] w-full max-w-xs flex-col items-center justify-center gap-y-4 rounded-[14px] p-[4px] pos-${index + 1} ${isCountingUp ? 'animated' : ''}`}
            >
              <dt className='text-base leading-7 text-gray-400'>{stat.name}</dt>
              <dd className='order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl'>
                {/* {stat.name !== "Uptime" ? (
                    <NumberCounter to={stat.value} from={stat.defaultValue} />
                  ) : (
                    stat.value
                  )} */}
                {/* {stat.value} */}
                <NumberCounter
                  from={stat.defaultValue}
                  to={stat.value}
                  format={stat.name == 'Uptime' ? '%' : stat.name == 'Tradable Pairs' ? 'k' : null}
                  setIsCountingUp={setIsCountingUp}
                />
              </dd>
            </div>
          ))}
        </dl>
      </AnimateWhileInView>
    </div>
  );
}
