import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';
import { IndexBlogCard } from '@/components/shared/lp/IndexBlogCard';

import KomoDeFi_DeFi from '@/public/images/lp/home/komodefi/KomoDeFi_DeFi.png';
import KomoDeFiDEX from '@/public/images/lp/home/komodefi/KomoDeFi_Exchange.png';
import KomoDeFiWallet from '@/public/images/lp/home/komodefi/KomoDeFi_Wallet.png';

const LatestNews = ({ blogPosts }: { blogPosts: any }) => {
  return (
    <section className='my-20 flex flex-col text-white lg:my-24 2xl:my-36'>
      <AnimateWhileInView delay={0.2}>
        <h2 className='text-center text-base font-normal leading-[20px] tracking-widest text-[#60A5FA] -lg:text-sm -lg:leading-[17px]'>
          News
        </h2>
      </AnimateWhileInView>
      <AnimateWhileInView delay={0.25}>
        <div className='mt-[10px] text-center'>
          <h3 className='mx-auto mb-[46px] max-w-[756px] font-heading text-3xl font-semibold text-white sm:text-4xl lg:text-5xl -sm:mb-0'>
            Latest News
          </h3>
          <div className='my-3 flex flex-col items-center space-y-4 md:my-6 lg:mb-0 lg:flex-row lg:items-stretch lg:space-x-[18px] lg:space-y-0 xl:my-12'>
            {blogPosts.map((post: any, index: any) => (
              <IndexBlogCard
                key={index}
                index={index}
                feature_image_info={post.feature_image_info}
                date={post.readable_published_at}
                dateIso={post.iso_published_at}
                title={post.title}
                description={post.meta_description.substring(0, 150) + ' ...'}
                href={post.permalink}
                tagName={post.tagName}
                readingTime={post.readingTime}
              />
            ))}
          </div>
        </div>
      </AnimateWhileInView>
    </section>
  );
};

export default LatestNews;
