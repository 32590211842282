import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import { Tab } from '@headlessui/react';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

import KomoDeFi_DeFi from '@/public/images/lp/home/komodefi/KomoDeFi_DeFi.png';
import KomoDeFiDEX from '@/public/images/lp/home/komodefi/KomoDeFi_Exchange.png';
import KomoDeFiWallet from '@/public/images/lp/home/komodefi/KomoDeFi_Wallet.png';
import DGBImg from '@/public/images/lp/home/showcase/DGBWallet.png';
import GleecImg from '@/public/images/lp/home/showcase/GleecDEX.png';
import MoonFiImg from '@/public/images/lp/home/showcase/MoonFi.png';
import MoonFiLogo from '@/public/images/lp/home/showcase/MoonFiLogo.png';
import FiroLogo from '@/public/images/lp/home/showcase/firo-logo.svg';
import GleecLogo from '@/public/images/lp/home/showcase/gleec-logo.svg';

//import FiroLogo from '@/public/images/lp/home/showcase/FiroLogo.png';
import Carousel from '../../Carousel';

const categories = [
  {
    name: 'MoonFi',
    description: (
      <>
        <Link
          href='https://moonfi.com/'
          target='_blank'
          className='listed-crypto border-b border-blue-500 underline'
          rel='noopener noreferrer'
        >
          MoonFi
        </Link>{' '}
        bridges Cosmos/IBC to native BTC, ETH, DOGE, MATIC & More and offers the widest protocol
        support
      </>
    ),
    image: MoonFiLogo,
  },
  {
    name: 'GleecDEX',
    description: (
      <>
        <Link
          href='https://dex.gleec.com/'
          target='_blank'
          className='listed-crypto border-b border-blue-500 underline'
          rel='noopener noreferrer'
        >
          GleecDEX
        </Link>{' '}
        is a wallet and decentralized exchange (DEX) built for the Gleec Community
      </>
    ),
    image: GleecLogo,
  },
  {
    name: 'FiroDEX',
    description: (
      <>
        <Link
          href='https://firo.org/2022/02/22/firodex-released.html'
          target='_blank'
          className='listed-crypto border-b border-blue-500 underline'
          rel='noopener noreferrer'
        >
          FiroDEX
        </Link>{' '}
        is a wallet and decentralized exchange (DEX) built for the Firo Community
      </>
    ),
    image: FiroLogo,
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Showcase = () => {
  return (
    <section className='mx-auto my-10 flex flex-col text-white lg:my-20'>
      <AnimateWhileInView delay={0.2}>
        <h2 className='mb-12 text-center font-heading text-5xl font-semibold leading-[4rem] text-white lg:text-[49.596px] -lg:text-4xl'>
          Powered by <br />
          <span className='trustless-gradient'>Komodo DeFi Framework</span>
        </h2>
      </AnimateWhileInView>
      <AnimateWhileInView delay={0.3}>
        <Carousel categories={categories} />
      </AnimateWhileInView>
    </section>
  );
};

export default Showcase;
