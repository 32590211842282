import ExportedImage from 'next-image-export-optimizer';

import { BanknotesIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import {
  ArrowsPointingOutIcon,
  GlobeAltIcon,
  LockOpenIcon,
  ShoppingCartIcon,
  WalletIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

import bitrefill from '@/public/images/lp/home/features/bitrefill.png';
import crossProtocolBridge from '@/public/images/lp/home/features/crossProtocolBridge.png';
import fiat from '@/public/images/lp/home/features/fiat.png';
import fullPrivacy from '@/public/images/lp/home/features/fullPrivacy.png';
import noKYC from '@/public/images/lp/home/features/noKYC.png';
import noncustodial from '@/public/images/lp/home/features/noncustodial.png';
import openSource from '@/public/images/lp/home/features/openSource.png';
import p2p from '@/public/images/lp/home/features/p2p.png';
import secure from '@/public/images/lp/home/features/secure.png';

const data = [
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[83.35px]'
        src={secure}
        alt='Secure'
      />
    ),
    title: 'Secure',
    description: 'Your assets are protected with robust encryption.',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[109.5px]'
        src={p2p}
        alt='Decentralized / P2P'
      />
    ),
    title: 'Decentralized / P2P',
    description: 'Direct transactions without intermediaries.',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[101px]'
        src={noncustodial}
        alt='Non-custodial'
      />
    ),
    title: 'Non-custodial',
    description: 'You control your keys, you control your funds.',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[82.68px]'
        src={openSource}
        alt='Free & open source'
      />
    ),
    title: 'Free & open source',
    description: 'Transparency and accessibility for all.',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[91.43px]'
        src={fiat}
        alt='Fiat on and (upcoming) off ramp'
      />
    ),
    title: 'Fiat on and (upcoming) off ramp',
    description: 'Seamless conversion between crypto and fiat',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[82.68px]'
        src={bitrefill}
        alt='Crypto shop (Bitrefill)'
      />
    ),
    title: 'Crypto shop (Bitrefill)',
    description: 'Access to a diverse range of goods and services',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[93.56px]'
        src={crossProtocolBridge}
        alt='Cross-protocol bridge'
      />
    ),
    title: 'Cross-protocol bridge',
    description: 'Interoperability across different blockchain networks.',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[113.76px]'
        src={noKYC}
        alt='No KYC'
      />
    ),
    title: 'No KYC',
    description: 'Instant access without intrusive verification.',
  },
  {
    img: (
      <ExportedImage
        placeholder='empty'
        className='absolute left-[30.83px] top-[24.75px] z-20 h-auto w-[90.37px]'
        src={fullPrivacy}
        alt='Full Privacy'
      />
    ),
    title: 'Full Privacy',
    description: 'Your data remains yours, always (even opt-out for analytics).',
  },
];

const Features = () => {
  return (
    <section className='my-20 text-white lg:my-24 2xl:my-36'>
      <AnimateWhileInView delay={0.2}>
        <h2 className='text-center text-base font-normal leading-[20px] tracking-widest text-[#60A5FA] -lg:text-sm -lg:leading-[17px]'>
          FEATURES
        </h2>
      </AnimateWhileInView>
      <AnimateWhileInView delay={0.1}>
        <div className='mt-[10px] text-center'>
          <h3 className='mx-auto mb-[46px] max-w-[756px] font-heading text-3xl font-semibold text-white sm:text-4xl lg:text-5xl'>
            Managing / Holding your crypto has never been easier
          </h3>
          <div className='mt-[45.96px]'>
            <div className='mx-auto flex w-full flex-col items-center gap-x-[33.33px] gap-y-[30px] lg:grid lg:w-[940px] lg:grid-cols-3 lg:justify-between xlm:w-[1180px] xlm:gap-x-[18px]'>
              {data.map(({ img, title, description }, index) => {
                return (
                  <AnimateWhileInView
                    classNaming='relative h-[231.02px] w-[280px] xl:w-[330px] xlm:w-[381.67px] -sm:w-[300px]'
                    delay={(index * 0.1) / 5}
                    key={title}
                  >
                    <div className='relative h-[231.02px] w-[280px] xl:w-[330px] xlm:w-[381.67px] -sm:w-[300px]'>
                      <div className='absolute left-[29.77px] top-[175.33px] z-10 h-[47.44px] w-[200px] rounded-full bg-gradient-to-l from-[#00a3ff] to-[#1d9ce4] blur-[34.50px] xl:w-[250px] xlm:w-[316.82px] -sm:left-[20.77px] -sm:w-[250px]' />
                      <div className='absolute left-0 top-0 z-10 h-[231.02px] w-[280px] rounded-[14px] border border-black bg-[#0d1d2a]/80 backdrop-blur-[81.20px] xl:w-[330px] xlm:w-[381.67px] -sm:w-[300px]' />
                      <div className='h-[231.02px] w-[280px] rounded-[14px] border border-[#1377d3] bg-[#0d1d2a]/80 backdrop-blur-[81.20px] xl:w-[330px] xlm:w-[381.67px] -sm:w-[300px]' />

                      <div className='absolute left-[37.21px] top-[129.95px] z-20 text-base font-semibold text-white -sm:left-[27.21px]'>
                        {title}
                      </div>
                      <div className='absolute left-[37.21px] top-[155.73px] z-20 w-[220px] text-left text-sm font-normal leading-[i9px] text-white xlm:w-[299px] -sm:left-[27.21px] -sm:w-[240px]'>
                        {description}
                      </div>
                      {img}
                    </div>
                  </AnimateWhileInView>
                );
              })}
              {/* {data.map(({ icon, title, description }, index) => {
                return (
                  <AnimateWhileInView delay={index / 5} key={title}>
                    <div className='flex items-center justify-center'>
                      <div className='isolate overflow-hidden bg-accentGradient p-4 md:p-6 lg:p-10 rounded-xl flex flex-col items-center gap-4 text-center -sm:w-[90%] -lg:w-[70%] lg:max-w-[37rem]'>
                        <div className='flex items-center justify-center p-4 rounded-full bg-br-blue'>
                          {icon}
                        </div>
                        <h4 className='text-xl font-semibold text-white sm:text-2xl font-heading'>
                          {title}
                        </h4>
                        <p className='text-lg leading-6 text-gray-300 '>{description}</p>
                      </div>
                    </div>
                  </AnimateWhileInView>
                );
              })} */}
            </div>
          </div>
        </div>
      </AnimateWhileInView>
    </section>
  );
};

// const Chrono = (content : {
//   icon: React.JSX.Element;
//   title: string;
//   description: string;
// }[]) => {
//   return (
//     <div className="flex flex-col grid-cols-9 p-2 mx-auto md:grid">
//       <div className="flex flex-row-reverse md:contents">
//         <div className="relative col-start-1 col-end-5 p-4 my-6 mr-auto text-black bg-white rounded-xl md:mr-0 md:ml-auto">
//           <h3 className="text-lg font-semibold lg:text-xl">New Event 1</h3>
//           <p className="mt-2 leading-6">Description of the first event.</p>
//         </div>
//         <div className="relative col-start-5 col-end-6 mr-7 md:mx-auto">
//           <div className="flex items-center justify-center w-6 h-full">
//             <div className="w-1 h-full rounded-t-full bg-primary"></div>
//           </div>
//           <div className="absolute w-6 h-6 -mt-3 bg-[#4c7155] border-4 border-[#5CAB70] rounded-full top-1/2"></div>
//         </div>
//       </div>

//       <div className="flex md:contents">
//         <div className="relative col-start-5 col-end-6 mr-7 md:mx-auto">
//           <div className="flex items-center justify-center w-6 h-full">
//             <div className="w-1 h-full bg-primary"></div>
//           </div>
//           <div className="absolute w-6 h-6 -mt-3 bg-[#4c7155] border-4 border-[#5CAB70] rounded-full top-1/2"></div>
//         </div>
//         <div className="relative col-start-6 col-end-10 p-4 my-6 mr-auto text-black bg-white rounded-xl">
//           <h3 className="text-lg font-semibold lg:text-xl">New Event 2</h3>
//           <p className="mt-2 leading-6">Description of the second event.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// function Features() {
//   return (
//     <div className="relative my-[10rem]">
//       <div className="absolute -top-1/3 -right-[35%] -z-10 w-[30rem] h-[30rem] bg-blobGradient rotate-90 rounded-full blur-3xl opacity-50"></div>
//       <h2 className="mb-4 text-sm font-bold tracking-widest md:text-base text-secondary">
//         FEATURES
//       </h2>
//       <h3 className="sm:max-w-[40rem] text-3xl sm:text-4xl md:text-5xl font-heading font-semibold md:leading-[4rem] text-white">
//         Managing / Holding your crypto has never been easier
//       </h3>
//     </div>
//   );
// }
export default Features;
