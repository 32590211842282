import React, { useEffect, useRef } from 'react';

import { siteData } from '@/data/site';

const SwapWidget = () => {
  const iframeRef = useRef(null);
  return (
    <div className='relative h-[550px] w-full overflow-hidden rounded-[1.125rem] border-none xs:h-[550px] xs:w-[430px]'>
      <iframe
        ref={iframeRef}
        title='Swap Widget'
        className='h-full w-full overflow-hidden rounded-[1.125rem] border-none'
        src={siteData.swapWidgetUrl}
      />
    </div>
  );
};

export default SwapWidget;
