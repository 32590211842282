import { motion } from 'framer-motion';

export default function CtaButton({ ...props }) {
  return (
    <motion.a
      href={props.href}
      target={props.newtab === 'yes' ? '_blank' : '_self'}
      rel='noopener noreferrer'
      className={`cta-btn flex h-14 w-48 select-none items-center justify-center rounded-[14px] border-none px-4 py-2 text-center font-heading font-semibold leading-6 text-white outline-none ${props.className ? props.className : ''}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
      {...props}
    >
      {props.children}
    </motion.a>
  );
}
